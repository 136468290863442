import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { template, templateSettings } from 'lodash';

const getParsedCertificate = (certificateData: TSFixMe) => {
  let templateString = certificateData.certificateTemplate;
  const { userName, productName, completionDate } = certificateData;
  if (!userName || !productName || !completionDate || !templateString) {
    return '<p>Sorry, something went wrong</p>';
  }

  templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  var compiled = template(templateString);

  const compiledString = compiled({
    userName,
    productTitle: productName,
    completionDate: format(parseISO(completionDate), 'do MMMM, yyyy'),
  });

  return compiledString;
};

export default getParsedCertificate;
