import { CertificateVersions } from 'modules/product/types';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

const certificatePreviewUrl = '/certificate';

const useLinkedInInfo = ({
  certificateId,
  certificateVersion,
  issueDate,
  expiryDate,
}: {
  certificateId: String;
  certificateVersion: CertificateVersions;
  issueDate?: String;
  expiryDate?: String;
}) => {
  const { site } = useInstanceConfig();

  return (certificateData: any) => ({
    certificateName: certificateData.productName,
    certificateAuthority: site.certificateAuthority || site.displayCompanyName,
    organizationId: site.linkedInOrganizationId || undefined,
    certificateId: certificateId,
    issueDate,
    expiryDate,
    certificateUrl: `${window.location.protocol}//${
      window.location.host
    }${certificatePreviewUrl}?id=${certificateId}&v=${
      certificateVersion === CertificateVersions.V2 ? '2' : '1'
    }`,
  });
};

export default useLinkedInInfo;
