import React from 'react';

import { Typography } from '@lyearn/ui';

const AwardMessage = ({
  userName,
  productTitle,
  isPublic,
}: {
  userName: string;
  productTitle: string;
  isPublic: string;
}) => {
  return (
    <>
      <Typography
        as="div"
        className="body-short-02 xs:body-short-03 completion uppercase text-text-secondary">
        Certificate of Completion
      </Typography>
      <Typography as="div" className="display-xxl xs:heading-xl congratulations text-text-white">
        {isPublic ? userName : `Congratulations, ${userName}! 🎉`}
      </Typography>
      <Typography
        as="div"
        className="body-short-01 xs:body-short-02 contentInfo text-text-placeholder">
        {isPublic
          ? 'Awarded a certificate for successfully completing'
          : 'You have been awarded a certificate for completing'}
        <Typography className="body-short-01 xs:body-short-02 contentName text-text-white">
          {' '}
          {productTitle}
        </Typography>
      </Typography>
    </>
  );
};

export default AwardMessage;
