import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import useGetCertificate from 'modules/certificate/hooks/useCertificateData';
import useLinkedInInfo from 'modules/certificate/hooks/useLinkedInInfo';
import { CertificateVersions } from 'modules/product/types';

import { Box } from '@lyearn/ui';

import { bottomButtonsContainer, previewContainer } from '../../styles';
import AwardMessage from '../AwardMessage';
import BottomButtons from '../BottomButtons';
import TopButtons from '../TopButtons';
import CertificateContent from './CertificateContent';

const BASE_HEIGHT = 750;
const BASE_WIDTH = 1030;

let certificateNode = '' as any;

/* Devide into Publiuc and private cert components */
/* refer src/app/components/ActionMenu/index.tsx in Lyearn-classroom */
const CertificatePreview = (props: any & { certificateVersion: CertificateVersions }) => {
  const {
    certificateId,
    certificateCompletionDate,
    certificateVersion,
    onLayoutCloseClick,
    isPublic,
  } = props;

  /* Fetch certificate JSON data and certificate template */
  const [fetchingCertificate, , certificateData] = useGetCertificate({
    certificateId,
    certificateVersion,
    certificateCompletionDate,
  });

  const TEMPLATE_BASE_HEIGHT = certificateData?.dimensions?.height ?? BASE_HEIGHT;
  const TEMPLATE_BASE_WIDTH = certificateData?.dimensions?.width ?? BASE_WIDTH;

  const getLinkedInInfo = useLinkedInInfo({
    certificateId,
    issueDate: certificateData.certificateCompletionDate,
    certificateVersion,
  });

  if (!certificateNode) {
    certificateNode = document.createElement('div');
    certificateNode.id = 'certificate-node';
    document.body.appendChild(certificateNode);
  }

  /* TODO(MS): refactor resizing and styling logic below */
  const { height, width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 1024);
  const availableWidth = width * (isMobile ? 0.8 : 1);
  const availableHeight = height - 300;
  const templateWidth = Math.max(availableWidth, TEMPLATE_BASE_WIDTH);
  const templateHeight = Math.max(availableHeight, TEMPLATE_BASE_HEIGHT);

  const [ratio, setRatio] = useState(
    isMobile ? availableWidth / templateWidth : availableHeight / templateHeight,
  );
  const [downloadOpened, setDownloadOpened] = useState(false);

  useEffect(() => {
    setIsMobile(width < 1024);
    const _availableWidth = width * (isMobile ? 0.8 : 1);
    const _availableHeight = height - 300;
    const _templateWidth = Math.max(availableWidth, TEMPLATE_BASE_WIDTH);
    const _templateHeight = Math.max(availableHeight, TEMPLATE_BASE_HEIGHT);
    setRatio(isMobile ? _availableWidth / _templateWidth : _availableHeight / _templateHeight);
  }, [width]);

  const onAction = () => {
    if (certificateData.certificatePdf) {
      var element = document.createElement('a');
      element.setAttribute('href', certificateData.certificatePdf);
      element.setAttribute('download', certificateData.certificatePdf);
      document.body.appendChild(element);
      element.click();
      return;
    }
    const iframe = document.getElementById('certificateIframe') as any;
    const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    const head = innerDoc.head || document.getElementsByTagName('head')[0];

    if (!head.hasChildNodes('style')) {
      const previewStyle = document.createElement('style');
      previewStyle.appendChild(
        document.createTextNode(`
        @font-face {
        font-family: 'CircularStd, sans-serif';
        font-weight: medium;
        font-style: normal;
        src: local('CircularStd Medium'), local('CircularStd-Medium'),
        url(https://d7jx2atmwmj9r.cloudfront.net/assets/fonts/circular-std/CircularStd-Medium.woff)
        format('woff');
        }
        @page { size: 279mm 221mm; margin: 5mm; }
        html,body{
        width: 297mm;
        height: 210mm;
        margin: 4mm auto;
        }`),
      );
      head.appendChild(previewStyle);
    }
    iframe && iframe.contentWindow.print();
    return null;
  };

  /* TODO: Rename to Header, Body Footer */
  const renderCertificatePreview = () => {
    if (fetchingCertificate || !certificateData.certificateHtml) {
      return <div>Getting certificate</div>;
    }

    return (
      <Box
        className={cn(
          'fixed inset-0 overflow-auto bg-text-primary',
          previewContainer(
            isMobile,
            TEMPLATE_BASE_HEIGHT * Math.min(0.7, ratio),
            Math.max(0.8, ratio) * TEMPLATE_BASE_WIDTH,
            ratio,
            certificateData?.certificateVersion,
          ),
        )}
        role="presentation"
        tabIndex={0}
        onClick={(event) => event.stopPropagation()}>
        <TopButtons hide={isPublic} isMobile={isMobile} onLayoutCloseClick={onLayoutCloseClick} />
        <Box
          className={cn('certificateInfo my-20 mb-50 w-full text-center', {
            'px-20 text-start': isMobile,
          })}>
          <AwardMessage
            isPublic={isPublic}
            productTitle={certificateData.productName}
            userName={certificateData.userName}
          />
        </Box>
        {/* renderOpenTab() */}
        <Box className={cn('certificateWrapper m-auto lg:mt-26')}>
          <CertificateContent certificateData={certificateData} ratio={Math.min(0.7, ratio)} />
        </Box>
        <Box className={bottomButtonsContainer}>
          <BottomButtons
            hide={isPublic}
            isMobile={isMobile}
            linkedInInfo={getLinkedInInfo(certificateData)}
            onAction={onAction}
          />
        </Box>
      </Box>
    );
  };
  return createPortal(renderCertificatePreview(), certificateNode);
};

export default CertificatePreview;
