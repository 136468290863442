import { CertificateVersions } from 'modules/product/types';

import { GetPublicCertificareQuery } from '../graphql/queries/generated/PublicCertificate';

const certificateImage = ({ imageUrl }: { imageUrl: string }) =>
  `<img src="${imageUrl}" alt="certificate" />`;

const transformV2CertData = (certificate: GetPublicCertificareQuery['publicCertificate']) => {
  const imageUrl = certificate?.issuedCertificate.image?.url;

  return {
    productName: certificate?.content?.name,
    userName: certificate?.user?.name,
    certificateHtml: imageUrl ? certificateImage({ imageUrl }) : null,
    certificatePdf: certificate?.issuedCertificate.pdf?.url,
    certificateCompletionDate: certificate?.issuedOn,
    dimensions: certificate?.issuedCertificate.dimensions,
    certificateVersion: CertificateVersions.V2,
  };
};

export default transformV2CertData;
