import { useGetPublicCertificareQuery } from '../graphql/queries/generated/PublicCertificate';
import transformV2CertData from '../utils/transformV2CertData';

let refetchCount = 1;

const useGetV2Certificate = ({ certificateId }: { certificateId: string }) => {
  const [{ fetching, data, error }, refetch] = useGetPublicCertificareQuery({
    variables: {
      filters: {
        sharedId: certificateId,
      },
    },
  });

  if (!data?.publicCertificate?.issuedCertificate.image && refetchCount <= 3) {
    setTimeout(() => {
      refetch();
      refetchCount = +1;
    }, 3000);
  }
  const transformedData = data ? transformV2CertData(data.publicCertificate) : {};

  return [fetching, error, transformedData];
};

export default useGetV2Certificate;
