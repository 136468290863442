import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import cn from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import linkedInPreFilledURL from 'modules/certificate/utils/linkedInPreFilledURL';

import { ArrowForward, Close, CopyIcon } from '@lyearn/icons';
import { Box, Button, DeprecatedIconButton, Dialog, Typography } from '@lyearn/ui';

import { closeIcon } from './styles';

interface AddToLinkedInModalProps {
  certificateName: string;
  certificateAuthority: string;
  organizationId: string;
  certificateId: string;
  issueDate: string;
  expiryDate: string;
  certificateUrl: string;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

const DetailWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box className="flex flex-row pb-16">{children}</Box>
);
const DetailLabel: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography className="w-160 flex-shrink-0 text-text-secondary" variant="body-short-02">
    {children}
  </Typography>
);
const DetailText: React.FC<React.PropsWithChildren<{ showCopy?: boolean; toCopy?: string }>> = ({
  children,
  showCopy = true,
  toCopy = null,
}) => (
  <Typography className="" variant="body-short-02">
    <>
      {children}
      {showCopy ? (
        <CopyToClipboard text={toCopy || (children as string)}>
          <CopyIcon className="inline h-18 w-18 cursor-pointer text-text-placeholder ms-4" />
        </CopyToClipboard>
      ) : null}
    </>
  </Typography>
);

/* TODO: use array items to render the items */
const AddToLinkedInModal = (props: AddToLinkedInModalProps) => {
  const {
    certificateName,
    certificateAuthority,
    organizationId,
    certificateId,
    issueDate,
    /* expiryDate, */
    certificateUrl,
    onClose,
  } = props;

  return (
    <Dialog classes={{ paper: cn('pt-26 px-40 pb-40') }} open={true} onClose={onClose}>
      <DeprecatedIconButton
        className={cn(closeIcon, 'absolute cursor-pointer p-10')}
        variant="ghost"
        onClick={onClose as any}>
        <Close className="h-20 w-20" />
      </DeprecatedIconButton>
      <Typography className="pb-16" variant="heading-m">
        Add to LinkedIn
      </Typography>
      <Typography variant="body-short-01">
        Add this to your LinkedIn account under the user profile section and let your professional
        network know about this awarded credential.
      </Typography>
      <Box className="pt-20 pb-14">
        <DetailWrapper>
          <DetailLabel>Certificate Name:</DetailLabel>
          <DetailText>{certificateName}</DetailText>
        </DetailWrapper>
        <DetailWrapper>
          <DetailLabel>Certificate authority:</DetailLabel>
          <DetailText>{certificateAuthority}</DetailText>
        </DetailWrapper>
        <DetailWrapper>
          <DetailLabel>License number:</DetailLabel>
          <DetailText>{certificateId}</DetailText>
        </DetailWrapper>
        <DetailWrapper>
          <DetailLabel>Issue Date:</DetailLabel>
          <DetailText showCopy={false}>{format(parseISO(issueDate), 'MMM yyyy')}</DetailText>
        </DetailWrapper>
        <DetailWrapper>
          <DetailLabel>Expiration Date:</DetailLabel>
          <DetailText showCopy={false}>This certificate doesn’t expire.</DetailText>
        </DetailWrapper>
        <DetailWrapper>
          <DetailLabel>Certificate URL:</DetailLabel>
          <DetailText toCopy={certificateUrl}>
            <a className="textlink-external" href={certificateUrl} rel="noreferrer" target="_blank">
              {certificateUrl}
            </a>
          </DetailText>
        </DetailWrapper>
      </Box>
      <Box>
        <Button
          appearance="primary"
          fullWidth={false}
          variant="outlined"
          onClick={() =>
            window.open(
              linkedInPreFilledURL(
                certificateName,
                certificateAuthority,
                organizationId,
                certificateId,
                format(parseISO(issueDate), 'yyyy'),
                format(parseISO(issueDate), 'M'),
                certificateUrl,
              ),
            )
          }>
          Go to LinkedIn <ArrowForward className="ms-4 rtl:rotate-180" />
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddToLinkedInModal;
