import { useEffect } from 'react';
import useRest from 'helpers/hooks/useRest';
import { FetchingStatus } from 'types/enums/FetchType';

import getParsedCertificate from '../utils/getParsedCertificate';
import useCertificateTemplate from './useCertificateTemplate';

const useGetV1Certificate = ({
  certificateId,
  certificateCompletionDate,
}: {
  certificateId: string;
  certificateCompletionDate: string;
}) => {
  const certificateUrl = `https://lyearn-store.s3.ap-south-1.amazonaws.com/resources/certificates/${certificateId}.json`;

  const [status, error, data, execute] = useRest(
    certificateUrl,
    {
      method: 'GET',
    },
    {},
    'json',
  );

  const [, , certificateTemplate, fetchCertificateTemplate] = useCertificateTemplate({
    templateId: data?.certificateTemplateId,
  });

  useEffect(() => {
    execute();
  }, []);

  useEffect(() => {
    if (status === FetchingStatus.Done && data?.certificateTemplateId) {
      fetchCertificateTemplate();
    }
  }, [status, data]);

  const certificateHtml = data ? getParsedCertificate({ ...data, certificateTemplate }) : '';

  const finalData = { ...data, certificateCompletionDate, certificateHtml };

  return [status !== FetchingStatus.Done, error, finalData];
};

export default useGetV1Certificate;
