/* TODO: rename function name to getLinkedInURl */
const linkedInPreFilledURL = (
  certificateName: string,
  certificateAuthority: string,
  organizationId: string,
  certificateId: string,
  issueYear: string,
  issueMonth: string,
  certificateUrl: string,
) => {
  const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURI(
    certificateName,
  )}&organizationId=${organizationId}${
    organizationId ? '' : `&organizationName=${encodeURI(certificateAuthority)}`
  }&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${encodeURIComponent(
    certificateUrl,
  )}&certId=${certificateId}`;

  return url;
};

export default linkedInPreFilledURL;
