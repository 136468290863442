import React, { useState } from 'react';
import cn from 'classnames';

import { Download, LinkedIn } from '@lyearn/icons';
import { Box, Button, Typography } from '@lyearn/ui';

import AddToLinkedInModal from '../AddToLinkedInModal';

const BottomButtons = ({
  onAction,
  isMobile,
  hide,
  linkedInInfo,
}: {
  onAction: any;
  isMobile: boolean;
  hide: boolean;
  linkedInInfo: any;
}) => {
  const [showLinkedInPopup, setShowLinkedInPopup] = useState(false);
  if (hide) {
    return null;
  }
  return (
    <Box
      className={cn('headerRoot flex w-full items-center justify-between', {
        'border-gray-900 h-50 border-b border-solid p-10': isMobile,
        'h-80 px-20': !isMobile,
      })}>
      <Button
        className="text-text-white hover:text-text-secondary "
        variant="ghost"
        onClick={() => setShowLinkedInPopup(!showLinkedInPopup)}>
        <Typography className="flex items-center hover:text-text-secondary" variant="heading-xxs">
          <LinkedIn className="h-20 w-20 fill-current me-10" />
          Add to LinkedIn
        </Typography>
      </Button>
      {showLinkedInPopup ? (
        <AddToLinkedInModal onClose={() => setShowLinkedInPopup(false)} {...linkedInInfo} />
      ) : null}
      <Button
        className="text-text-white hover:text-text-secondary "
        variant="ghost"
        onClick={onAction}>
        <Typography className="flex items-center hover:text-text-secondary" variant="heading-xxs">
          <Download className="h-20 w-20 fill-current me-10" />
          Download as PDF
        </Typography>
      </Button>
    </Box>
  );
};

export default BottomButtons;
