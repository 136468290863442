import { CertificateVersions } from 'modules/product/types';

import useGetV1Certificate from './useGetV1Certificate';
import useGetV2Certificate from './useGetV2Certificate';

const certificateVersionHooks = {
  [CertificateVersions.V1]: useGetV1Certificate,
  [CertificateVersions.V2]: useGetV2Certificate,
};

/* TODO: optimize fetchs */
const useGetCertificate = ({
  certificateId,
  certificateVersion,
  certificateCompletionDate,
}: {
  certificateId: string;
  certificateVersion: CertificateVersions;
  certificateCompletionDate?: string;
}) => {
  const hook = certificateVersionHooks[certificateVersion];
  return hook({ certificateId, certificateCompletionDate: certificateCompletionDate! });
};

export default useGetCertificate;
