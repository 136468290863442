import { css } from '@emotion/css';
import { CertificateVersions } from 'modules/product/types';

export const previewContainer = (
  isMobile: boolean,
  height: number,
  width: number,
  ratio: number,
  certificateVersion: CertificateVersions,
) => css`
  z-index: 1001;
  overflow: auto;
  outline: none;
  .tabWrapperContainer {
    bottom: 3rem;
    .tabWrapper {
      width: ${width}px;
    }
  }

  .headerRoot {
    z-index: 1002;
  }

  .certificateWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${height}px;
    ${isMobile &&
    css`
      margin-top: 7rem;
    `}
  }

  .certificateContent {
    ${certificateVersion !== CertificateVersions.V2
      ? `transform: ${
          isMobile ? `scale(${Math.min(0.7, ratio)})` : `scale(${Math.min(0.7, ratio)})`
        };`
      : ''}
  }
`;

export const bottomButtonsContainer = css`
  max-width: 721px;
  margin: 30px auto 0px;
`;
