import React from 'react';
import cn from 'classnames';

import { ArrowLeftOutlineLegacy } from '@lyearn/icons';
import { Box, Button, Typography } from '@lyearn/ui';

const TopButtons = ({
  onLayoutCloseClick,
  isMobile,
  hide,
}: {
  onLayoutCloseClick: any;
  isMobile: boolean;
  hide: boolean;
}) => {
  if (hide) {
    return null;
  }
  return (
    <Box
      className={cn('headerRoot flex w-full items-center justify-between', {
        'border-gray-900 h-50 border-b border-solid p-10': isMobile,
        'h-80 px-20': !isMobile,
      })}>
      <Button variant="ghost" onClick={(event) => onLayoutCloseClick(event)}>
        <Typography
          className="flex items-center text-text-white hover:text-text-placeholder"
          variant="heading-xxs">
          <ArrowLeftOutlineLegacy className="h-30 w-30 fill-current rtl:rotate-180" />
          Back
        </Typography>
      </Button>
    </Box>
  );
};

export default TopButtons;
