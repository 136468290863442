import { useCallback } from 'react';
import useRest from 'helpers/hooks/useRest';

const certificateTemplateUrl = 'https://d7jx2atmwmj9r.cloudfront.net/assets/certificate-templates/';

const useCertificateTemplate = ({ templateId }: { templateId: string }) => {
  const [status, error, data, execute] = useRest(
    '',
    {
      method: 'GET',
    },
    {},
    'text',
  );

  const fetchTemplate = useCallback(() => {
    if (templateId) {
      execute({
        url: `${certificateTemplateUrl}${templateId}.html`,
      });
    }
  }, [certificateTemplateUrl, templateId]);
  return [status, error, data, fetchTemplate];
};

export default useCertificateTemplate;
