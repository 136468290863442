import React from 'react';
import { CertificateVersions } from 'modules/product/types';

import { Box } from '@lyearn/ui';

import CertificateIframe from './CertificateIframe';

/* TODO: Update props name - remove 'certificate' */
const CertificateContent = (props: any) => {
  const {
    certificateData: { certificateHtml, dimensions, certificateVersion },
    ratio,
  } = props;

  const style = React.useMemo(
    () =>
      certificateVersion === CertificateVersions.V2
        ? {
            height: dimensions?.height * ratio,
            width: dimensions?.width * ratio,
          }
        : null,
    [certificateVersion, ratio, dimensions?.height, dimensions?.width],
  );

  if (!certificateHtml) {
    return <div>getting certificate</div>;
  }

  return (
    <Box className="certificateContent relative origin-center bg-white" style={style}>
      <Box dangerouslySetInnerHTML={{ __html: certificateHtml }} />
      <CertificateIframe>
        <Box dangerouslySetInnerHTML={{ __html: certificateHtml }} />
      </CertificateIframe>
    </Box>
  );
};

export default CertificateContent;
