import React, { Component } from 'react';
import { createPortal } from 'react-dom';

/* TODO: remove Iframe logic */
export default class CertificateFrame extends Component<React.PropsWithChildren> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentRef: '',
    };
    (this as any).setContentRef = (node: any) => {
      if (node && node.contentWindow && node.contentWindow.document.body) {
        this.setState({ contentRef: [node.contentWindow.document.body] });
      }
    };
  }

  componentDidMount = () => {
    const { contentRef } = this.state as any;
    if (contentRef && contentRef.contentWindow && contentRef.contentWindow.document.body) {
      this.setState({ contentRef: [contentRef.contentWindow.document.body] });
    }
  };

  render() {
    const { children, ...props } = this.props;
    const { contentRef } = this.state as any;

    return (
      <iframe
        {...props}
        ref={(this as any).setContentRef}
        className="hidden"
        id="certificateIframe"
        title="Certififcate Iframe">
        {contentRef.length && createPortal(React.Children.only(children), contentRef[0])}
      </iframe>
    );
  }
}
